import React from 'react';
import Slider from 'react-slick';
import mobilemaui from '../assets/mobilemaui.jpg'; // Asegúrate de que la imagen esté en tu proyecto
import mobilereactjs from '../assets/movilereactjs.jpg';
import lambdadev from '../assets/lambdadev.jpg';

const Blog = () => {
  // Configuración para el carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,  // Mostrar 3 imágenes a la vez
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Para pantallas medianas
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,  // Para pantallas pequeñas
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  // Arreglo con imágenes y enlaces a Substack
  const articles = [
    {
      image: mobilemaui,
      link: "https://silviotraverso.substack.com/p/ventajas-de-desarrollar-aplicaciones?r=4jhf8p",
      title: "Ventajas de desarrollar aplicaciones móviles"
    },
    {
      image: mobilereactjs,
      link: "https://open.substack.com/pub/silviotraverso/p/aplicaciones-moviles-con-reactjs?r=4jhf8p",
      title: "Aplicaciones Móviles con React.js"
    },
    {
      image: lambdadev,
      link: "https://open.substack.com/pub/silviotraverso/p/desarrollar-microservicios-con-aws?r=4jhf8p",
      title: "Desarrollar Microservicios con AWS Lambda"
    }
  ];

  return (
    <section className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-4 text-center">Artículos del Blog en Substack</h2>
        <p className="text-center text-gray-700 mb-8">Visita nuestro blog en Substack para leer los últimos artículos.</p>

        <div className="text-center">
          <Slider {...settings}>
            {articles.map((article, index) => (
              <div key={index} className="px-4">
                <a href={article.link} target="_blank" rel="noopener noreferrer">
                  <img 
                    src={article.image} 
                    alt={article.title} 
                    className="rounded-lg shadow-lg w-full h-96 object-cover" 
                  />
                  <p className="mt-4 text-lg font-semibold">{article.title}</p>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Blog;

