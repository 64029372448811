import Axios from '../Axios';
import { fetchUser } from '../utils/fetchUser';

export const getContactos = async () => {
    try {
      
      const {data}  = await Axios.get(
        '/contacto');
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const crearContacto = async (contactoData) => {
    try {
        
        const {data}  = await Axios.post(
            '/contacto', contactoData
        );
        return  data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateContacto = async (contactoData) => {
    try {

        const {data}  = await Axios.patch(
          `/contacto/${contactoData._id}`, contactoData
        );

        return  data;
      } catch (error) {
        return Promise.reject(error);
      }
  };

  export const deleteContacto = (Id) => Axios.delete(`/contacto/${Id}`);
