import React from 'react';
import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <section className="relative h-screen text-black flex items-center justify-center bg-white">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between p-8 rounded">
        <div className="md:w-1/2 p-4">
          <h1 className="text-5xl font-bold">C|TEAM - transformando ideas en realidad</h1>
          <p className="mt-4 text-xl">Somos un equipo de expertos en tecnología que transforma ideas en soluciones innovadoras. Trabajamos con pasión para llevar tus proyectos al siguiente nivel.</p>
          <div className="mt-6">
            <Link to="contact" smooth={true} duration={500}>
              <button className="bg-blue-500 text-white px-4 py-2 rounded mr-4 hover:bg-blue-600 transition-colors duration-300">Quiero que me contacten</button>
            </Link>
            <Link to="services" smooth={true} duration={500}>
              <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300">Conoce más</button>
            </Link>
          </div>
        </div>
        <div className="md:w-1/2 p-4">
          <img src="img/first.jpg" alt="CTeam" className="rounded shadow-lg" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
