import React, { useState } from 'react';
import { useContactoMutations } from '../hooks';
import { toast } from 'react-hot-toast';

const Contact = () => {
  const { addContacto } = useContactoMutations();
  const [formData, setFormData] = useState({
    origen: 'CTEAM',
    nombre: '',
    email: '',
    mobile: '',
    mensaje: '',
    estado: 'INICIO',
  });

  const [errors, setErrors] = useState({
    origen: 'CTEAM',
    nombre: '',
    email: '',
    mobile: '',
    mensaje: '',
    estado: 'INICIO',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!formData.nombre) {
      isValid = false;
      errors.nombre = 'El nombre es requerido.';
    }
    if (!formData.email) {
      isValid = false;
      errors.email = 'El email es requerido.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = 'El email no es válido.';
    }
    if (!formData.mobile) {
      isValid = false;
      errors.mobile = 'El teléfono es requerido.';
    } else if (!/^[0-9]{11}$/.test(formData.mobile)) {
      isValid = false;
      errors.mobile = 'El teléfono no es válido.';
    }
    if (!formData.mensaje) {
      isValid = false;
      errors.mensaje = 'El mensaje es requerido.';
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        await addContacto.mutateAsync( formData )

        setFormData({
          origen: 'CTEAM',
          nombre: '',
          email: '',
          mobile: '',
          mensaje: '',
          estado: 'INICIO',
        });
        
        toast.success('Gracias por contactarnos, a la brevedad nos pondremos en contacto.');

      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        alert('Hubo un problema al enviar el mensaje');
      }
    }
  };

  return (
    <section 
      id="contact" 
      className="relative h-screen text-black flex items-center justify-center bg-white"
    >
      <div className="p-8 rounded max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-4 text-left">Contáctanos</h2>
        <p className="text-lg mb-4">¿Tienes alguna pregunta o estás listo para comenzar un proyecto?.</p>
        <p className="text-lg mb-4">¡Ponte en contacto con nuestro equipo! Estaremos encantados de atender tus solicitudes y brindarte una solución a la medida de tus necesidades.</p>
        <form className="text-left" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Nombre</label>
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
            {errors.nombre && <p className="text-red-500 text-sm">{errors.nombre}</p>}
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div className="w-1/2">
              <label className="block text-gray-700">Teléfono</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded"
              />
              {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Mensaje</label>
            <textarea
              name="mensaje"
              value={formData.mensaje}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            ></textarea>
            {errors.mensaje && <p className="text-red-500 text-sm">{errors.mensaje}</p>}
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300"
          >
            Enviar
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;

