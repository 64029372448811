import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-200 py-8">
      <div className="container mx-auto text-center">

        <div className="flex justify-center space-x-4 mb-4">
          <a href="https://twitter.com/@ContactoFiados">
            <img src="img/icons8-twitter-30.png" alt="Twitter - X" />
          </a>
          <a href="https://facebook.com/ContactoFiados">
            <img src="img/icons8-facebook-30.png" alt="Facebook" />
          </a>
          <a href="http://www.instgram.com/#ContactoFiados">
            <img src="img/icons8-instagram-30.png" alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/in/contacto-fiados-46a734298">
            <img src="img/icons8-linkedin-30.png" alt="LinkedIn" />
          </a>
          <a href="https://www.youtube.com/@ContactoFiados">
            <img src="img/icons8-youtube-30.png" alt="YouTube" />
          </a>
          <a href="mailto:silvio.traverso@cteam.cl">
            <img src="img/icons8-correo-30.png" alt="Correo" />
          </a>
        </div>
        <div className="text-gray-600">
          ©2024 Powered by C|Team Spa
        </div>
      </div>
    </footer>
  );
};

export default Footer;
