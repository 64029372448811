const env = process.env.REACT_APP_NODE_ENV;

const apiConfig = {
  dev: {
    API_BASE: 'https://webcommerceserver-production.up.railway.app',
   // API_BASE: 'http://localhost:5000',
  },
  prod: {
    API_BASE: 'https://server-production-a29c.up.railway.app',
  },
  testing: {
    API_BASE: 'https://server-production-a29c.up.railway.app',
  },
  SOCKET: {
    development: 'ws://localhost',
    production: 'ws://localhost',
    testing: 'ws://ec2-54-227-82-206.compute-1.amazonaws.com',
  },
  env,
};

export default apiConfig;
