import React, { useState } from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-blue-600 p-4 fixed w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <Link
          to="home"
          smooth={true}
          duration={500}
          className="text-white text-2xl font-bold cursor-pointer"
        >
          C|Team
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.36 6.64a1 1 0 010 1.41L13.41 13l4.95 4.95a1 1 0 01-1.41 1.41L12 14.41l-4.95 4.95a1 1 0 01-1.41-1.41L10.59 13 5.64 8.05a1 1 0 011.41-1.41L12 11.59l4.95-4.95a1 1 0 011.41 0z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 6h16a1 1 0 110 2H4a1 1 0 110-2zm0 5h16a1 1 0 110 2H4a1 1 0 110-2zm0 5h16a1 1 0 110 2H4a1 1 0 110-2z"
                />
              )}
            </svg>
          </button>
        </div>
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } w-full lg:flex lg:items-center lg:w-auto`}
        >
          <div className="text-sm lg:flex-grow">
            <Link
              to="home"
              smooth={true}
              duration={500}
              className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 cursor-pointer"
            >
              Home
            </Link>
            <Link
              to="aboutus"
              smooth={true}
              duration={500}
              className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 cursor-pointer"
            >
              Nosotros
            </Link>
            <Link
              to="services"
              smooth={true}
              duration={500}
              className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 cursor-pointer"
            >
              Servicios
            </Link>
            <Link
              to="blog"
              smooth={true}
              duration={500}
              className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 cursor-pointer"
            >
              Blog
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="block mt-4 lg:inline-block lg:mt-0 text-white cursor-pointer"
            >
              Contáctenos
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
