import React from 'react';

const Services = () => {
  return (
    <section id="services" className="relative min-h-screen text-black bg-white flex flex-col items-center justify-center">
      <div className="container mx-auto p-8 relative z-10">
        <div className="mb-8 text-left">
          <h2 className="text-4xl font-bold">Nuestros Servicios</h2>
        </div>
        <div className="md:w-full">
            <h3 className="text-xl font-semibold mb-2">Nuestros desarrollos son escalables en el tiempo para que tu organización mantenga siempre el camino hacia la innovación digital. Gracias a nuestra amplia base de conocimiento, realizamos desarrollos de software bajo cualquier plataforma que necesite nuestro cliente.</h3>
          </div>
        
        <div className="flex flex-col md:flex-row justify-around text-center mt-8">
          <div className="md:w-1/3">
            <h3 className="text-xl font-semibold mb-2">Desarrollo de Software</h3>
            <p>Creamos soluciones a medida utilizando tecnologías como MERN, .NET Core, y más.</p>
          </div>
          <div className="md:w-1/3">
            <h3 className="text-xl font-semibold mb-2">Integración de Sistemas</h3>
            <p>Implementamos API, microservicios y procesos de ETL en la nube para una transformación digital exitosa.</p>
          </div>
          <div className="md:w-1/3">
            <h3 className="text-xl font-semibold mb-2">Consultoría Tecnológica</h3>
            <p>Brindamos asesoría experta en áreas como arquitectura de software, optimización de procesos y estrategia de TI.</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-around text-center mt-8">
          <div className="md:w-2/3">
            <h3 className="text-xl text-left font-semibold mb-2">Desarrollo de Aplicaciones Móviles</h3>
            <p>Transformamos tus ideas en aplicaciones móviles de alta calidad y rendimiento. Utilizamos las últimas tecnologías y mejores prácticas para crear experiencias únicas y funcionales en plataformas iOS y Android.</p>
          </div>
          <div className="md:w-1/3 p-4">
              <img src="img/mobile3.jpg" alt="CTeam" className="rounded shadow-lg w-full h-36 object-contain" />
          </div>

        </div>


      </div>
    </section>
  );
};

export default Services;
