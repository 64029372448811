import React from 'react';

const AboutUs = () => {
  return (
    <section id="aboutus" className="relative min-h-screen text-black bg-white flex flex-col items-center justify-center">
      <div className="container mx-auto p-8 relative z-10">
        <div className="mb-8 text-left">
          <h2 className="text-4xl font-bold">Quienes Somos</h2>
        </div>

        <div className="md:w-full">
            <h3 className="text-xl font-semibold mb-2">C|Team esta conformado por profesionales con amplia experiencia en la consultoría, liderazgo y una tremenda trayectoria prestando servicios tecnológicos, en C|Team afirmamos que somos tan expertos como la suma de todas las experiencias de los grandes profesionales que componen nuestro equipo, todos comprometidos en obtener logros para nuestros clientes y ayudar a consolidar su crecimiento.</h3>
          </div>


        <div className="flex flex-col md:flex-row justify-around text-center mt-8">
          <div className="md:w-1/3">
            <h3 className="text-xl font-semibold mb-2">1. Expertos en Tecnología</h3>
            <p>Contamos con un equipo de profesionales altamente calificados en diversas áreas de la tecnología.</p>
          </div>
          <div className="md:w-1/3">
            <h3 className="text-xl font-semibold mb-2">2. Innovación Constante</h3>
            <p>Mantenemos una mentalidad ágil y adaptable para ofrecer soluciones a la vanguardia.</p>
          </div>
          <div className="md:w-1/3">
            <h3 className="text-xl font-semibold mb-2">3. Enfoque en Resultados</h3>
            <p>Nos esforzamos por entregar proyectos de alto impacto que superen las expectativas de nuestros clientes.</p>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-cover bg-center opacity-20" style={{ backgroundImage: "url('https://via.placeholder.com/1200x800')" }}></div>
    </section>
  );
};

export default AboutUs;
